import React from 'react';
import { GeneralModal } from './Modal';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row,Button} from 'reactstrap';





const Payment = (props) => {


  const [planId, setPlanID] = React.useState(process.env.REACT_APP_NEW_YEARLY);
  const [payment_url, setPaymentAPI] = React.useState(process.env.REACT_APP_PAYMENT_API_PROD);
  const [env, setEnv] = React.useState('prod');
  const [generalModalSettings, setGMSettings] = React.useState(false)
  

  const handleCloseGeneralModal = () => {
    setGMSettings({...generalModalSettings, isOpen : false})
  };
  const handleCloseThenRedirectGeneralModal = () => {
    setGMSettings({isOpen : true, message : `Redirecting you...`, type : 'loading', closeCB : () =>{return}  })

    var tID = setTimeout(function () {
      window.location.href = "/Members";
      window.clearTimeout(tID);		// clear time out.
  }, 1000);
  };
  
  React.useEffect(() => {
    switch(process.env.REACT_APP_HOST_ENV){

      case 'PROD' : {
        setEnv('prod')
        setPaymentAPI(process.env.REACT_APP_PAYMENT_API_PROD)
        break;
      }
      case 'BETA' : {
        setEnv('beta')
        setPaymentAPI(process.env.REACT_APP_PAYMENT_API_BETA)
        break;
      }
      case 'DEV' : {
        setEnv('dev')
        setPaymentAPI(process.env.REACT_APP_PAYMENT_API_DEV)
        break;
      }
      case 'LOCAL' : {
        setEnv('')
        setPaymentAPI(process.env.REACT_APP_PAYMENT_API_LOCAL)
        break;
      }

    }

    if(props.plan === 1){ //monthly

        if(props.price === 4){

          setPlanID(process.env.REACT_APP_NEW_MONTHLY)

        }
        else if (props.price === 1 ){

          setPlanID(process.env.REACT_APP_REC_MONTHLY)
        }
        else
          return
    }
    else if (props.plan === 2){ //yearly

      if(props.price === 40){

        setPlanID(process.env.REACT_APP_NEW_YEARLY)

      }
      else if (props.price === 10 ){

        setPlanID(process.env.REACT_APP_REC_YEARLY)
      }
      else
        return

    }
    else
      return

  },[planId])

  window.addEventListener("message",  (event) => {

    if (event.origin !== payment_url)
      return;
  
    event.stopImmediatePropagation();
    
      switch(event.data.action){

        case 'APPROVED' : {

          setGMSettings({isOpen : true, message : `Payment complete - Thank you!`, type : 'success', closeCB : handleCloseThenRedirectGeneralModal})
          console.log(event.data)
          props.upload(event.data.orderID);
          break;
        }
        case 'FIRED' : {
          setGMSettings({isOpen : true, message : `Please complete the payment in the pop up window`, type : 'loading', closeCB : () =>{return}  });
          console.log(event.data)
          break;
        }
        case 'ABORT' : {
          setGMSettings({isOpen : true, message : `Payment process was aborted - please try again`, type : 'error', closeCB : handleCloseGeneralModal  });
          console.log(event.data)
          break;
        }
        case 'CANCEL' : {
          setGMSettings({isOpen : true, message : `Payment process was canceled`, type : 'error', closeCB : handleCloseGeneralModal});
          console.log(event.data)
          break;
        }
        case 'ERROR' : {
          setGMSettings({isOpen : true, message : `An error occured - Please try again later`, type : 'error', closeCB : handleCloseGeneralModal});
          console.log(event.data)
          break;
        }

      }
    // ...
  }, false);

  return (
    <>
    <GeneralModal open={generalModalSettings.isOpen} type = {generalModalSettings.type} text = {generalModalSettings.message} onClose = {generalModalSettings.closeCB}>
        <GeneralModal.GeneralModalContent/>         
    </GeneralModal>

    <div className="main">
        <Container  className= " text-center p-3">
          <h2>Subscription</h2>
          <h5>You selected the {props.plan === 1 ? 'monthly' : 'yearly' } Plan at ${props.price}.00 a  {props.plan === 1 ? 'month' : 'year' } </h5>
          <Row  className= " mt-4 text-center mx-auto justify-content-center w-100">
      

 <iframe className= " mt-4 text-center mx-auto justify-content-center w-100"   
  src={`${payment_url}/${env}/${props.Token}/${planId}`}  
  id="myFrame" 
  width="100%" 
  onError={(e)=>console.log(e)}
  height={'500px'} 
  scrolling="no" 
  
  frameBorder="0">
</iframe> 

          </Row>
        </Container>
      </div>
    </>
  );
};

const MapStateToProps = state => {

  return {
    Token : state.UserReducer.Token,
  }

}
export default connect(MapStateToProps, null)(withRouter(Payment));
